// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,

  
 // apiURL: "https://localhost:7080/api/Admin/",
  // apiURL: "https://localhost:7071/api/Admin/",
  //arun
  //apiURL: "https://localhost:44342/api/Admin/",
    
  //DEVELOPMENT
  //apiURL: "https://devswankapi.aspiromit.com/api/Admin/",
  
   //Test
   //apiURL: "https://swanktestapi.imstp.co/api/Admin/",


  //apiURL: "https://81be-111-92-63-14.ngrok.io/api/Admin/",

   apiURL:"https://jewelleryapi.aspirom.in/api/Admin/",

  contactId:"7452b7d4-2984-430a-aaf4-365c622fb09d",

  
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
