import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { LoaderEnabled } from '../../loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    apiurl = environment.apiURL;

    user: User;

    constructor(private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    @LoaderEnabled()

    login(email: string, password: string) {
        // return getFirebaseBackend().loginUser(email, password).then((response: any) => {
        //     const user = response;
        //     return user;
        // });
        let obj={
            Email:email,
            Password:password
        }
       return this.http.post(this.apiurl + 'Account/Login',obj);

    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
     @LoaderEnabled()

    resetPassword(email: string) {
       
       return this.http.get(this.apiurl + 'Account/ForgotPassword?email='+email);

    }
    @LoaderEnabled()

    verifyOtp(email: string, otp:string) {
       
       return this.http.post(this.apiurl + 'Account/ForgotPasswordOtpVerify?email='+email+'&otp='+otp,null);


    }
    @LoaderEnabled()

    updatePassword(verifyOtpDto:any) {
       
       return this.http.post(this.apiurl + 'Account/ResetPassword',verifyOtpDto);

    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        localStorage.removeItem('Role');
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');

        //getFirebaseBackend().logout();
    }
}

