import { Component , OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  constructor(private cookieService: CookieService) { }
  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    if(this.cookieService.get('lang') == 'ar'){
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    }
  
  }
}
