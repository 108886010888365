<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="text-center d-sm-block">
                    {{ 'FOOTER.SWANK' | translate}} 2022 {{ 'FOOTER.ALLRIGHTSRESERVED' | translate}}
                </div>
            </div>
        </div>
    </div>
</footer>