import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoaderEnabled } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class LogoAndAdsService {
  apiurl = environment.apiURL;
  constructor(private http: HttpClient) { }
  @LoaderEnabled()
  ImgUpload(formData:any)
  {
    return this.http.post<any>(this.apiurl + 'Logo/LogoUpload', formData, { reportProgress: true, observe: 'events' });
  }
  @LoaderEnabled()
  GetCurrentLogo() {
    return this.http.get(this.apiurl + 'Logo/GetCurrentLogo');
  }
  @LoaderEnabled()
  AdCarouselAd(carouselAdDto :any) {
    return this.http.post(this.apiurl + 'CarouselAd/CreateCarouselAd',carouselAdDto );
  }
  @LoaderEnabled()
  GetAllCarousel() {
    return this.http.get(this.apiurl + 'CarouselAd/GetAllActiveCarouselAds');
  }
  @LoaderEnabled()
  GetAllProductsByCategoryId(Id:any) {
    //
    return this.http.get(`${this.apiurl}Product/GetAllProductsByCategoryId?ProductCategoryUUID=${Id}`);
  }
  @LoaderEnabled()
  DeleteCarousel(Id:any){
    //
    return this.http.delete(this.apiurl + 'CarouselAd/DeleteCarouselAD?CarouselAdUUID='+Id);

  }
  @LoaderEnabled()
  GetSingleCarousel(id:any){
    return this.http.get(this.apiurl + 'CarouselAd/GetSingleCarouselAdById?CarouselAdUUID='+id);
  }
  @LoaderEnabled()
  GetSingleCategoryByCategoryId(id:any){
    return this.http.get(this.apiurl + 'CarouselAd/GetAllActiveCategories',id);
  }
  @LoaderEnabled()
  Update(id:any,formData:any) {
  //
    return this.http.put(this.apiurl +  'CarouselAd/UpdateCarouselAd?CarouselAdUUID='+id, formData);
  }
  
}
