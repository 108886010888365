import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class LanguageService {
  public languages: string[] = ["en", "ar"];
  public selectedlanguage: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.cookieService.get("lang"));
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check("lang")) {
      browserLang = this.cookieService.get("lang");
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|ar/) ? browserLang : "en");
    this.setLanguage(browserLang) //added by nevin
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set("lang", lang);
    if (lang == "ar") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
    this.selectedlanguage.next(lang);
  }
}
