import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { User } from '../models/auth.models';
import Swal from 'sweetalert2';
import { LoaderEnabled } from '../../../app/loader/loader.service';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
  apiurl = environment.apiURL;

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    submitted: boolean;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    @LoaderEnabled()
    login(email: string, password: string) {
        
        let obj={
            Email:email,
            Password:password
        }
        
        return this.http.post<any>(this.apiurl +`Account/Login`, obj)
            .pipe(map(user => {
               // 
               

                // if (user.status == 200) 
                // {
                //      // login successful if there's a jwt token in the response
                //     if (user && user.payload.token) {
                //     localStorage.setItem('Role', user.payload.type);
                //     localStorage.setItem('token', user.payload.token);
                //     // store user details and jwt token in local storage to keep user logged in between page refreshes
                //     localStorage.setItem('currentUser', JSON.stringify(user.payload.user));
                //     this.currentUserSubject.next(user);
                //     }

                //     for (var val of user.responseMessage) 
                //     {
                //       if (val.code == 200) 
                //       {
                //         this.submitted = false;
                //          Swal.fire('', val.message, 'success');
                //       }
                //       else 
                //       {
                //         Swal.fire('Error', val.message, 'error'); 
        
                //       }
                //     }
                // }
                //   else if (user.status == 500) 
                //   {
                //     for (var val of user.responseMessage) {
                //       if (val.code != 200) {
                //         Swal.fire('Error', val.message, 'error'); 
                //       }
                //     }
                //   }
                //   else{
                //     for (var val of user.responseMessage) {
                //         if (val.code != 200) {
                //           Swal.fire('Error', val.message, 'error'); 
                //         }
                //       }
                //   }

                return user;
            }));

        
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('Role');
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');

        this.currentUserSubject.next(null);
    }
}
