<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block">
        <div class="navbar-brand-box">
          <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/mobile-logo.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17">
            </span>
          </a>

          <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/mobile-logo.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="19">
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>



<div class="topnav">
  <div class="container-fluid topnav-headermenus">

    <div class="d-flex">
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/mobile-logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-gold.svg" alt="">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light mobilelogo-css">
          <span class="logo-sm">
            <img src="assets/images/logo-gold.svg" alt="">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-gold.svg" alt="">
          </span>
        </a>
      </div>
    </div>

    <div class="d-flex">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div class="collapse navbar-collapse active" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none " routerLink="/dashboard" routerLinkActive="active" tabindex="1"
                id="topnav-components"> {{ 'HEADER.DASHBOARD.DASHBOARD' | translate}}
              </a>
            </li>
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none" routerLink="/categories" routerLinkActive="active" tabindex="2"
                id="topnav-components">{{ 'HEADER.DASHBOARD.CATEGORIES' | translate}}
              </a>
            </li>
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none" routerLink="/products" routerLinkActive="active" tabindex="3"
                id="topnav-components">{{ 'HEADER.DASHBOARD.PRODUCTS' | translate}}
              </a>
            </li>
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none" routerLink="/orders" routerLinkActive="active" tabindex="4"
                id="topnav-components">{{ 'HEADER.DASHBOARD.ORDERS' | translate}}
              </a>
            </li>
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none" routerLink="/customers" routerLinkActive="active" tabindex="5"
                id="topnav-components">{{ 'HEADER.DASHBOARD.CUSTOMERS' | translate}}
              </a>
            </li>
            <li class="nav-item dropdown top-nave-item">
              <a class="nav-link dropdown-toggle arrow-none" routerLink="/settings" routerLinkActive="active" tabindex="6"
                id="topnav-components">{{ 'HEADER.DASHBOARD.SETTINGS' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <!-- <div class="collapse navbar-collapse active" id="topnav-menu-content">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of menuItems">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                  id="topnav-components" [routerLink]="item.link" role="button">
                  <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                    *ngIf="hasItems(item)"></div>
                </a>
  
                <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                  href="javascript: void(0);" id="topnav-components" role="button">
                  <i class="bx {{item.icon}} me-1"></i>
                  {{item.label| translate }}
                  <div class="arrow-down"></div>
                </a>
  
                <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                  <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                    <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                     {{subitem.label | translate }}
                </a>
                <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                        <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                        <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                        <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                    </div>
                </div>
                
                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link"
                          routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">
  
                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
               </div>
              </li>
            </ng-container>
          </ul>
        </div> -->
      </nav>
    </div>

    <div *ngIf="currentlang=='en' " class="sidesicons-noti english-secnoti" >
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <span class="dash-admin d-none d-xl-inline-block ms-1">{{ 'HEADER.LOGIN.ADMIN' | translate}}</span>
            <i _ngcontent-qfe-c339="" class="bx bxs-user"></i>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ 'HEADER.LOGIN.PROFILE' | translate}}</a> -->
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div  class="dropdown d-inline-block" ngbDropdown>
          <button (click)="ChangeLanguage('0')" type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <span   class="ms-1">عربى</span>
            <!-- <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
            <span *ngIf="flagvalue === undefined" class="ms-1">English</span> -->
          </button>
          <!-- <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <img src="{{item.flag}}" alt="user-image" class="ms-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div> -->
        </div>
      </div> 
    </div>

    <div *ngIf="currentlang=='ar' " class="sidesicons-noti arabic-secnoti" >
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <span class="dash-admin d-none d-xl-inline-block ms-1">{{ 'HEADER.LOGIN.ADMIN' | translate}}</span>
            <i _ngcontent-qfe-c339="" class="bx bxs-user"></i>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ 'HEADER.LOGIN.PROFILE' | translate}}</a> -->
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div  class="dropdown d-inline-block" ngbDropdown>
          <button (click)="ChangeLanguage('1')" type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <span   class="ms-1">English</span>
            <!-- <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
            <span *ngIf="flagvalue === undefined" class="ms-1">English</span> -->
          </button>
          <!-- <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <img src="{{item.flag}}" alt="user-image" class="ms-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div> -->
        </div>
      </div> 
    </div>
     
    </div>
  </div>
