import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
//import { LoaderService } from "../service/loader.service";
import Swal from "sweetalert2";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        //private loaderService: LoaderService
        ) {

    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        //console.log("Test");
        if (err.status === 403) {
            //navigate /delete cookies or whatever
            this.router.navigateByUrl(`/dashboard`);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (localStorage.getItem('token') != null) {
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                .set('Access-Control-Allow-Origin', '*')
                .set("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => { },
                    err => {
                        if (err.status == 401){
                            
                            localStorage.removeItem('token');
                            this.router.navigateByUrl('/account/login');
                        }
                        else if(err.status == 403){
                            //this.notifyService.showError('Permission Denied', 'Failed');
                             Swal.fire('Error!', 'Permission Denied', 'error');

                            //this.loaderService.isLoading.next(true);
                            //this.router.navigateByUrl('/dashboard');
                        }
                    }
                )
                //catchError(x=> this.handleAuthError(x))
            )
        }
        else
            return next.handle(req.clone());
    }
}
